export let environment = {
  production: true,
  url: "https://prod.api.edition.pagesuite.com/",
  screenshotUrl: "https://ik3m4wvksj.execute-api.eu-west-1.amazonaws.com/prod/",
  pushurl: "https://o5q5gwnz6e.execute-api.eu-west-1.amazonaws.com/dev/",
  timezones: "https://dev-feed-editions.s3-eu-west-1.amazonaws.com/timezones.json",
  replica2pdf: "https://b4pz5qc8vi.execute-api.eu-west-1.amazonaws.com/prod/",
  cachedUrl: "https://ep.prod.pagesuite.com/prod/",
  oldPortal: "https://live.portal.pagesuite.com/"
};


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/Rx';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { LoadingService } from './loading.service';
import { HttpHeaders } from '@angular/common/http';
import { ApplicationsService } from "./applications.service";
import { FeedsService } from './feeds.service';
import { App } from '../models/App';
import { User } from '../models/User';
import { Feed } from '../models/Feed';
import { BehaviorSubject, Observable } from 'rxjs/Rx';
import {REST} from "../models/enums/api-end-points";

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  private $loggedInUser: BehaviorSubject<User>;

  public getUser(): Observable<User> {
    return this.$loggedInUser.asObservable();
  }
  public setUser(user: User): void {
    this.$loggedInUser.next(user);
  }
  public getUserAccountGuid(): string {
    return this.$loggedInUser.value.accountguid;
  }

  public pagesuiteAccountGUID(): any[] {
    if(typeof this.$loggedInUser.value.data["PageSuiteProfessionalAccountGUID"] != 'undefined') {
      return this.$loggedInUser.value.data["PageSuiteProfessionalAccountGUID"];
    } else {
      return [];
    }

  }

  constructor(private http: HttpClient,
              private router: Router,
              private loadingsrv: LoadingService,
              private appsrc: ApplicationsService,
              private feedService: FeedsService) {
    this.$loggedInUser = new BehaviorSubject<User>(new User("", "", "", false, "", "", "", "", {}));

    if (this.statusHtmlStorage('currentUser')) {
      var decrypted = CryptoJS.AES.decrypt(localStorage.getItem('currentUser_enc'), "pagesuiteangular")
      this.$loggedInUser.next(JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted)));
    }
    else {
      this.setUser(new User("", "", "", false, "", "", "", "", {}));
    }
  }

  logout() {
    this.setUser(new User("", "", "", false, "", "", "", "", {}));
    this.appsrc.setAllApps(new Array<App>());
    this.appsrc.setActiveApplication(null);
    this.feedService.setFeedArray(new Array<Feed>());
    this.removeHtmlStorage('currentUser');
    this.router.navigate(['/signin']);
  }

  accountGUID(): string {
    return this.$loggedInUser.value.accountguid;
  }




  signin(email: string, password: string): Observable<Boolean> {
    this.loadingsrv.setLoading();
    let postData = { 'password': password };

    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    var options = {
      headers: headers
    };

    let url = REST.LOGIN;
    return this.http.post(url.toString().replace("{username}", email), JSON.stringify(postData), options).map((res: User) => {
      return this.extractData(res);
    });
  }

  private extractData(body: any): boolean {
    this.loadingsrv.setFinished();

    if (body['Error']) {
      /* Incorrect login details */
      return false;
    }
    else {
      console.log(body);
      this.setHtmlStorage('currentUser', JSON.stringify(body), 100000);
      this.setUser(body);
      this.appsrc.loadApplications(this.$loggedInUser.value.accountguid);
      return true;
    }

  }

  private removeHtmlStorage(name: string) {
    localStorage.removeItem(name + '_enc');
    localStorage.removeItem(name + '_time');
  }

  private statusHtmlStorage(name: string) {
    var date = new Date();
    var current = Math.round(+date / 1000);
    // Get Schedule
    var stored_time: string = localStorage.getItem(name + '_time');
    if (stored_time === undefined || stored_time == 'null') { stored_time = ''; }
    // Expired
    if (parseInt(stored_time) < current) {
      // Remove
      this.removeHtmlStorage(name);
      return false;
    } else {
      if (stored_time === null) {
        return stored_time;
      }
      return true;
    }
  }

  private setHtmlStorage(name: string, value: string, expires: number) {
    var encrypted = CryptoJS.AES.encrypt(value, "pagesuiteangular");
    var date = new Date();
    var schedule = Math.round((date.setSeconds(date.getSeconds() + expires)) / 1000);

    localStorage.setItem(name + '_enc', encrypted.toString());
    localStorage.setItem(name + '_time', schedule.toString());
  }

  public isAuthenticated() {
    if (this.$loggedInUser.value.accountguid) {
      if (this.$loggedInUser.value.accountguid.length > 0) {
        return true;
      }
    }
    return false;
  }
}

import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';   // use this
import { HttpRequestInterceptor } from './HttpRequestInterceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { PagesComponent } from './home/pages/pages.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { AppRoutingModule } from './app-routing.module';
import { HomeModule } from './home/home.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MAT_LABEL_GLOBAL_OPTIONS, MatSelectModule, MatSnackBarModule, MatAutocomplete } from "@angular/material";
import { ExistingArticlesComponent } from "./apps/editions/view-section/existing-articles/existing-articles.component";
import { EditArticleNavComponent } from "./apps/editions/view-section/shared/edit-article-nav/edit-article-nav.component";
import { ArticleCategoriesComponent } from "./apps/editions/view-section/shared/article-categories/article-categories.component";
import { ViewSpecialsComponent } from "./apps/editions/view-section/view-specials/view-specials.component";
import { FormsModule } from "@angular/forms";
import { AddsectionComponent } from './apps/flatplan/addsection/addsection.component';
import { TemplateListComponent } from "./apps/editions/view-section/shared/templates/template-list.component";
import { SharedModule } from "./modules/shared/shared.module";
import { AddpageComponent } from './apps/flatplan/addpage/addpage.component';
import { SelecttemplateComponent } from './apps/flatplan/selecttemplate/selecttemplate.component';
import { MessageDialogModule } from "./modules/message-dialog-module/message-dialog.module";
import { NewFlatplanComponentComponent } from "./apps/flatplans/new-flatplan-component/new-flatplan-component.component";
import { LoadingComponent } from "./loading/loading.component";
import { AddEditionModalComponent } from "./apps/editions/add-edition/add-edition-modal/add-edition-modal.component";
import { NewSectionComponent } from './apps/editions/view-edition/new-section/new-section.component';
import { SectionFrontEditorComponent } from './apps/editions/view-section/section-front-editor/section-front-editor.component';
import { EditionPageTypeComponent } from './apps/editions/view-section/edition-page-type/edition-page-type.component';
import { PuzzlePageComponent } from './apps/editions/view-section/edition-page-type/puzzle-page/puzzle-page.component';
import { AddAppToKioskComponent } from './apps/kiosk/add-app-to-kiosk/add-app-to-kiosk.component';
import { AddAppToArchiveComponent } from './apps/archive/add-app-to-archive/add-app-to-archive.component';


import { RenderFeedStyleAComponent } from './apps/sections/render-feed-style-a/render-feed-style-a.component';
import { RenderFeedStyleBComponent } from './apps/sections/render-feed-style-b/render-feed-style-b.component';
import { RenderFeedPapersCollectionComponent } from './apps/sections/render-feed-papers-collection/render-feed-papers-collection.component';
import { RenderFeedReplicaComponent } from './apps/sections/render-feed-replica/render-feed-replica.component';
import { RenderFeedBlankComponent } from './apps/sections/render-feed-blank/render-feed-blank.component';
import { NewFeedTemplateComponent } from './apps/sections/new-feed-template/new-feed-template.component';
import { RenderFeedTopImageComponent } from './apps/sections/render-feed-top-image/render-feed-top-image.component';
import { FlatplanAdvertsComponent } from './apps/flatplan/flatplan-adverts/flatplan-adverts.component';
import { CustomisationProcessingComponent } from './apps/view-app/components/app-customisation/components/customisation-processing/customisation-processing.component';
import { AddEditionFromFeedModalComponent } from './apps/editions/add-edition/add-edition-from-feed-modal/add-edition-from-feed-modal.component';
import { EditEditionPropertiesComponent } from './apps/editions/view-edition/edit-edition-properties/edit-edition-properties.component';
import { ImageErrorDirective } from './image-error.directive';
import { RefreshArticlesComponent } from './apps/editions/view-section/edition-page-type/refresh-articles/refresh-articles.component';
import { CustomisationViewComponent } from './apps/view-app/components/app-customisation/components/customisation-view/customisation-view.component';
import { NewPushCreationComponent } from './apps/push/push-summary/components/new-push-creation/new-push-creation.component';
import { PdfGenerationComponent } from './apps/editions/view-edition/pdf-generation/pdf-generation.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PagesComponent,
    ExistingArticlesComponent,
    ViewSpecialsComponent,
    EditArticleNavComponent,
    ArticleCategoriesComponent,
    TemplateListComponent,
    AddsectionComponent,
    AddpageComponent,
    SelecttemplateComponent,
    LoadingComponent,
    AddEditionModalComponent,
    NewSectionComponent,
    SectionFrontEditorComponent,
    EditionPageTypeComponent,
    PuzzlePageComponent,
    AddAppToKioskComponent,
    AddAppToArchiveComponent,
    RenderFeedStyleAComponent,
    RenderFeedStyleBComponent,
    RenderFeedPapersCollectionComponent,
    RenderFeedReplicaComponent,
    RenderFeedBlankComponent,
    NewFeedTemplateComponent,
    RenderFeedTopImageComponent,
    FlatplanAdvertsComponent,
    AddEditionFromFeedModalComponent,
    RefreshArticlesComponent,
    EditEditionPropertiesComponent,
    PdfGenerationComponent,
  ],
  imports: [
    BrowserModule,
    AngularFontAwesomeModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatSelectModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    HomeModule,
    FormsModule,
    HttpClientModule,
    HttpClientModule,
    MessageDialogModule
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ExistingArticlesComponent,
    ViewSpecialsComponent,
    AddsectionComponent,
    AddpageComponent,
    TemplateListComponent,
    SelecttemplateComponent,
    TemplateListComponent,
    AddEditionModalComponent,
    NewSectionComponent,
    SectionFrontEditorComponent,
    EditionPageTypeComponent,
    PuzzlePageComponent,
    AddAppToKioskComponent,
    AddAppToArchiveComponent,
    RenderFeedStyleAComponent,
    RenderFeedStyleBComponent,
    RenderFeedTopImageComponent,
    RenderFeedPapersCollectionComponent,
    RenderFeedReplicaComponent,
    RenderFeedBlankComponent,
    NewFeedTemplateComponent,
    FlatplanAdvertsComponent,
    AddEditionFromFeedModalComponent,
    EditEditionPropertiesComponent,
    RefreshArticlesComponent,
    PdfGenerationComponent
  ],
  providers: [
    {provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'never'}},
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true }
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class AppModule { }


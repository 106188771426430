import { Component, OnInit } from '@angular/core';
import {EditionService} from "../../../../services/edition.service";
import {ModalService} from "../../../../modal/services/modal.service";

@Component({
  selector: 'app-new-section',
  templateUrl: './new-section.component.html',
  styleUrls: ['./new-section.component.css']
})
export class NewSectionComponent implements OnInit {

  sectionName: string;
  sectionColor: string;

  constructor(private editionService: EditionService, private modalService: ModalService) { }

  ngOnInit() {
  }

  createNewSection() {
    this.editionService.createNewSection(this.sectionName, this.sectionColor).then((res) => {
      console.log(res);
      this.editionService.refreshEdition.next();
      this.modalService.destroy();
    });
  }

}

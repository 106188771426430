import {FlatplanPlatform} from './FlatplanPlatform';


export class FlatplanPage {

  private _type: string;
  private _platforms: FlatplanPlatform[];
  private _isFullPage: boolean;
  private _articleCount: string;
  private _level: string;

  constructor(type: string, platforms: FlatplanPlatform[], isFullPage: boolean, articleCount: string, level: string) {
    this._type = type;
    this._platforms = platforms;
    this._isFullPage = isFullPage;
    this._articleCount = articleCount;
    this._level = level;

  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get isFullPage(): boolean {
    return this._isFullPage;
  }

  set isFullPage(value: boolean) {
    this._isFullPage = value;
  }

  get articleCount(): string {
    return this._articleCount;
  }

  set articleCount(value: string) {
    this._articleCount = value;
  }

  get level(): string {
    return this._level;
  }

  set level(value: string) {
    this._level = value;
  }

  get platforms(): FlatplanPlatform[] {
    return this._platforms;
  }

  set platforms(value: FlatplanPlatform[]) {
    this._platforms = value;
  }


}

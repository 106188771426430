import {Component, OnDestroy, OnInit} from '@angular/core';
import {Flatplan} from "../../../../models/Flatplan";
import {App} from "../../../../models/App";
import {untilComponentDestroyed} from "@w11k/ngx-componentdestroyed";
import {ApplicationsService} from "../../../../services/applications.service";
import {LoadingService} from "../../../../services/loading.service";
import {ApiService} from "../../../../services/api.service";
import {EditionService} from "../../../../services/edition.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ModalService} from "../../../../modal/services/modal.service";
import {Location} from "@angular/common";

@Component({
  selector: 'app-add-edition-from-feed-modal',
  templateUrl: './add-edition-from-feed-modal.component.html',
  styleUrls: ['./add-edition-from-feed-modal.component.css']
})
export class AddEditionFromFeedModalComponent implements OnInit, OnDestroy {

  app: App;
  flatPlans: Flatplan[];
  flatplan: string;
  showCompleted = false;
  hasError = false;

  constructor(private appService: ApplicationsService, private loadingService: LoadingService, private api: ApiService,
              private editionService: EditionService, private modalService: ModalService, private urlLocation: Location) { }

  ngOnInit() {
    this.appService.getActiveApplication().pipe(untilComponentDestroyed(this)).subscribe((app: App) => {
      this.app = app;
      this.loadingService.setFinished();
      this.api.getFlatplans(app.app.guid).then((flats: Array<Flatplan>) => {
        this.flatPlans = flats;
      });
    });
  }

  ngOnDestroy() {

  }

  createNewEdition() {
    if(this.flatplan) {
      this.loadingService.setLoading();
      let job = {
        "accountGUID": this.appService.getStaticAppState().app.accountguid,
        "applicationGUID": this.appService.getStaticAppState().app.guid,
        "flatplanGUID": this.flatplan
      };
      this.editionService.addJob(job)
        .then(res => this.editionService.createNewEdition(res['guid']))
        .then((edRes) => {
          this.loadingService.setFinished();
          this.showCompleted = true;
          // this.modalService.destroy();
          console.log(edRes);
          setTimeout(() => {
            this.urlLocation.back();
            this.modalService.destroy();
        }, 6000);
      });
    } else {
      this.hasError = true;
    }
  }

}

import { Component, OnInit } from '@angular/core';
import {ApplicationsService} from "../../../../services/applications.service";
import {App} from "../../../../models/App";
import {Edition} from "../../../../models/edition";
import {EditionService} from "../../../../services/edition.service";
import {ActivatedRoute, Router} from "@angular/router";
import {LoadingService} from "../../../../services/loading.service";
import {ModalService} from "../../../../modal/services/modal.service";
import {ToastService} from "../../../../services/toast.service";

@Component({
  selector: 'app-edit-edition-properties',
  templateUrl: './edit-edition-properties.component.html',
  styleUrls: ['./edit-edition-properties.component.css']
})
export class EditEditionPropertiesComponent implements OnInit {

  activeApp: App;
  edition: Edition;

  editionName: string;
  editionArticleMode: string;
  platformId = 2;

  constructor(private appService: ApplicationsService,
              private editionService: EditionService,
              private loadingService: LoadingService,
              private modalService: ModalService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.platformId = this.editionService.currentPlatformId;
    this.activeApp = this.appService.getStaticAppState();
    this.edition = this.editionService.jsonedition;
    this.editionName = this.edition.name;
    this.editionArticleMode = this.edition.articlemode;
  }

  saveEdition() {
    this.loadingService.setLoading();

    this.editionService.updateEdition(this.editionName, this.editionArticleMode).then(res => {
      this.loadingService.setFinished();
      console.log(res);
      this.toastService.success("Edition Updated");
      this.editionService.jsonedition.name = this.editionName;
      this.editionService.jsonedition.articlemode = this.editionArticleMode;
      this.modalService.destroy();
    }).catch(err => {
      this.loadingService.setFinished();
      this.toastService.failed("Error updating edition");
      console.error(err);

    });
  }

}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LoadingService } from '../services/loading.service';
import { AuthService } from '../services/auth.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private loadingService: LoadingService, private authService: AuthService, private router: Router, private route: ActivatedRoute) { }

  @ViewChild('text') texts: ElementRef;
  @ViewChild('password') password: ElementRef;

  public monkey = "Monkey_Middle.png";
  public space = "spacemonkeyicon";
  public error = "";

  ngOnInit(): void { }

  enterUp(): void { }

  authenticateUser(result: Boolean): void {
    if (result === true) {
      this.router.navigate(['/apps'], { relativeTo: this.route });
      console.log('Yes');
      this.error = "";
    }
    else {
      this.error = "The password is incorrect";
    }
  }

  changevizor() {
    this.space = "spacemonkeydown";
    this.monkey = "Monkey_Closed.png";
  }

  login(): void {
    this.loadingService.setLoading();

    this.authService.signin(this.texts.nativeElement.value, this.password.nativeElement.value).subscribe(result => {
      this.authenticateUser(result),
        e => console.log('onError: %s', e),
        () => console.log('onCompleted')
    });
  }

  capturecontent() {
    this.space = "spacemonkeyicon";

    switch (true) {
      case this.texts.nativeElement.value.length < 4:
        this.monkey = "Monkey_Left2.png";
        break;
      case this.texts.nativeElement.value.length < 8:
        this.monkey = "Monkey_Left1.png";
        break;
      case this.texts.nativeElement.value.length < 12:
        this.monkey = "Monkey_MiddleDown.png";
        break;
      case this.texts.nativeElement.value.length < 16:
        this.monkey = "Monkey_Right2.png";
        break;
      case this.texts.nativeElement.value.length < 20:
        this.monkey = "Monkey_Right1.png";
        break;
    }
  }
}

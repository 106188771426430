import { Injectable } from '@angular/core';
import { REST } from '../models/enums/api-end-points';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Template, TemplateType } from '../models/Template';
import { Page } from '../models/page';
import { Section } from '../models/Section';
import { Edition } from '../models/edition';
import { Article } from '../models/Article';
import { Observable } from 'rxjs';
import { Feed } from '../models/Feed';
import { FunctionService } from './function.service';
import { Mapping } from '../models/Mapping';
import { App } from '../models/App';
import { Flatplan } from '../models/Flatplan';
import { Schedule, ScheduleFrequency } from '../models/Schedule';
import { Product } from '../models/Product';
import { TemplateService } from './template.service';
import {FeedTemplate} from '../models/FeedTemplate';
import {arch} from 'os';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private func: FunctionService) { }

  private jsonOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
  
  

  getSchedules(appGuid: string): Promise<Schedule[]> {

    return new Promise((resolve, reject) => {
      this.http.get(REST.SCHEDULES.replace('{APPGUID}', appGuid)).toPromise().then((res: Array<Schedule>) => {
        resolve(res);
      }).catch(err => {
        console.error("Couldn't get schedules: ", err);
        reject(err);
      })
    });

  }

  getGoogleKey() : Promise<JSON>
  {
    let url = REST.GET_GOOGLE_KEY;
    return new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then((res: JSON) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }


  getFeed(feedguid: string)
  {
    let url = REST.GET_FEED.toString().replace('{feedguid}', feedguid);
    return new Promise((resolve, reject) => {
      this.getFeedContent(url).then(res => {
        resolve(res["Articles"]);
      });
    });
  }

  getAllApps(accountGuid: string): Promise<Array<App>> {
    console.log("GET ALL APPS");
    let url = REST.GET_APPLICATIONS.toString().replace('{accountGuid}', accountGuid);
    return new Promise((resolve, reject) => {
      this.http.get(url).toPromise().then((res: Array<App>) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public getApp(): Promise<App> {
    return new Promise((resolve, reject) => {
      this.http.get(REST.NEW_APP).toPromise().then((res: App) => {
        let archive = res['archive'];
        if (archive != null && archive.length > 0 && archive[0].items.length > 0) {
          let firstArchive = archive[0];
          if (firstArchive != null && firstArchive.items.length > 0) {
            let firstItem = firstArchive.items[0];
            let applicationGuid = res['app']['guid'];
            firstItem.guid = applicationGuid;
          }
        }

        let application = this.mapAppObj(res);
        resolve(application);
      }).catch(err => {
        reject(err);
      })
    });
  }

  public getFlatplans(appGUID: string): Promise<Array<Flatplan>> {
    console.log(appGUID);
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_FLATPLANS.toString().replace('{applicationGuid}', appGUID)).toPromise().then((res: any[]) => {
        console.log(res);
          const array = Array<Flatplan>();
          res.forEach(fp => {
            console.log(fp);
            array.push(this.mapFlatplan(fp.data, fp.flatplanguid));
          });
          resolve(array);

      }).catch(err => {
        reject(err);
      })
    });
  }

  public getTimezones(): Promise<JSON> {
    let url = environment["timezones"];
    return new Promise((resolve, reject) => {
      this.http.get(url).toPromise()
      .then((res: JSON) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public getFlatplan(flatplanGuid: string): Promise<Flatplan> {
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_FLATPLAN.toString().replace('{flatplanGuid}', flatplanGuid)).toPromise().then((res: any) => {
        let flatplan;
        if (res.name == 'error' || res.name == null) {
          flatplan = new Flatplan(2,'new', [], "", "");
        } else {
          flatplan = this.mapFlatplan(res, flatplanGuid);
        }
        resolve(flatplan);
      }).catch(err => {
        reject(err);
      })
    });
  }

  public createFlatplan(flatplan: Flatplan, appGuid: string) {
    let flatplanToSend = this.func.recursivelyRemoveUnderscoreJSONKeys(flatplan);
    console.log(flatplanToSend)
    return new Promise((resolve, reject) => {
      this.http.post(REST.CREATE_FLATPLAN.toString().replace('{applicationGuid}', appGuid), flatplanToSend).toPromise().then((res: any) => {
        resolve(res.flatplanguid);
      }).catch(err => {
        reject(err);
      })
    });
  }

  public saveTemplates(content: any) {
    console.log(JSON.stringify(content));
    return new Promise((resolve, reject) => {
      this.http.post(REST.SAVE_FEED_TEMPLATES.toString(), content).toPromise().then((res: any) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    });
  }


  public saveFlatplanChanges(flatplan: Flatplan) {
    let flatplanGuid = flatplan.flatplanguid;
    let flatplanToSend = this.func.recursivelyRemoveUnderscoreJSONKeys(flatplan);
    delete flatplanToSend.flatplanguid;
    console.log(flatplanToSend);
    return new Promise((resolve, reject) => {
      this.http.put(REST.SAVE_FLATPLAN.toString().replace('{flatplanGuid}', flatplanGuid), flatplanToSend).toPromise().then((res: any) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    });
  }

  public getEditions(applicationGUID: string, from: number, count: number): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = REST.GET_EDITIONS.toString().replace('{from}', from.toString()).replace('{count}', count.toString()).replace('{applicationGuid}', applicationGUID);
      this.http.get(url).toPromise().then((res: any) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public createBlankEdition(name: string, flow: string, appGuid: string): Promise<any> {
    const body = {
      name: name,
      flow: flow,
      appGuid: appGuid
    };
    return new Promise((resolve, reject) => {
      this.http.put(REST.NEW_EDITION.toString(), body, this.jsonOptions).toPromise().then((res: any) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  createNewSection(name: string, color: string, editionGuid: string, order: number) {
    let body = {
      editionGuid: editionGuid,
      section: {
        name: name,
        color: color,
        order: order
      }
    }
    return new Promise((resolve, reject) => {
      this.http.put(REST.NEW_SECTION.toString(), body, this.jsonOptions).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public getEdition(editionGUID, platformId: number): Promise<Edition> {
    console.log(editionGUID);
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_EDITION.toString().replace('{editionGuid}', editionGUID).replace('{platformId}', platformId.toString())).toPromise().then(res => {

        console.log(res);

        let Pages = new Array<Page>(), Sections = new Array<Section>();

        res['pages'].forEach(page => {
          Pages.push(this.mapPageObj(page));
        });

        res['sections'].forEach(section => {
          Sections.push(this.mapSectionObj(section));
        });

        resolve(new Edition(
          editionGUID,
          res['name'],
          Pages,
          Sections,
          res['islive'],
          res['pubdate'],
          res['flow'],
          res['datemode'],
          res['articlemode'],
          res['lastprocessed'],
          res['applicationguid'],
          res['replicajobid'],
          res['finishedprocessing'],
          res['pubdate'],
          res['pdfeditionguid'],
          res['replicajobs']
        ));

      }).catch(err => {
        reject(err);
      });
    });
  }

  public getGlobalTemplates(): Promise<Template[]> {
    var main = this;
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_GLOBAL_TEMPLATES).toPromise().then((res: Template[]) => {
        let globalTpls: Template[] = new Array<Template>();
        res.forEach((template: Template) => {
          //TODO: Remove this line when feed is updated with the type 14/09/2018

          if (template.data.type == null)
          {
            template.data.type = TemplateType.FRONT;
          }

          globalTpls.push(this.mapTemplateObj(template));
        });
        globalTpls.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0));
        //main.tempservice.setGlobalTemplates(globalTpls);
        resolve(globalTpls);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public getAccountTemplates(accountGuid: string): Promise<Template[]> {
    var main = this;
    return new Promise((resolve, reject) => {
      console.log(REST.GET_ACCOUNT_TEMPLATES + accountGuid);
      this.http.get(REST.GET_ACCOUNT_TEMPLATES + accountGuid).toPromise().then((res: Template[]) => {
        let accountTpls: Template[] = new Array<Template>();

        res.forEach((template: Template) => {
          if (template.data.type == null)
          {
            template.data.type = TemplateType.FRONT;
          }
          accountTpls.push(this.mapTemplateObj(template));
        });
        accountTpls.sort((a,b) => (a.data.name > b.data.name) ? 1 : ((b.data.name > a.data.name) ? -1 : 0));
        //main.tempservice.setAccountTemplates(accountTpls);
        resolve(accountTpls);
      }).catch(err => {
        reject(err);
      })
    })
  }

  public postTemplates(): void {

  }

  public getProducts(applicationGuid: string): Promise<Product[]> {
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_PRODUCTS.toString().replace('{applicationGuid}', applicationGuid)).toPromise().then((res: Product[]) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public postProduct(product: Product): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.http.post(REST.CREATE_PRODUCT, product).toPromise().then((res: Product) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }

  public updateProduct(product: Product): Promise<Product> {
    return new Promise((resolve, reject) => {
      this.http.put(REST.CREATE_PRODUCT + '/' + product.productguid, product).toPromise().then((res: Product) => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    });
  }


  getFeedTemplates(): Promise<Array<FeedTemplate>>
  {
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_FEEDS_TEMPLATES.toString()).toPromise().then((res: Array<FeedTemplate>) => {
        let feedtemplates: Array<FeedTemplate> = new Array<FeedTemplate>();
        res.forEach(feedtemplate => {
          feedtemplates.push(this.mapFeedTemplateObj(feedtemplate));
        });
        resolve(feedtemplates);
      }).catch(err => {
        reject(err);
      })
    })
  }

  getFeedsFromAccountGuid(accountGuid: string): Promise<Array<Feed>> {
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_FEEDS.toString().replace('{accountGuid}', accountGuid)).toPromise().then((res: Array<Feed>) => {
        let feeds: Array<Feed> = new Array<Feed>();
        res.forEach(feed => {
          feeds.push(this.mapFeedObj(feed));
        });
        resolve(feeds);
      }).catch(err => {
        reject(err);
      })
    })
  }

  postSchedule(schedule: Schedule): Promise<any> {
    let options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) },
      body =
      {
        "name": schedule.name,
        "flatplanGUID": schedule.flatplanguid,
        "scheduleType": schedule.scheduletype,
        "firstRuntimeUTC": schedule.nextruntimeutc
      }

    console.log('>>>>> Schedule:', schedule);

    return new Promise((resolve, reject) => {
      this.http.post(REST.SCHEDULES.replace('{APPGUID}', schedule.applicationguid), body, options).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      });
    })
  }

  deleteSchedule(appGuid: string, schedulerGuid: string): Promise<any> {
    let url = REST.DELETE_SCHEDULES.replace("{APPGUID}", appGuid);
    url = url.replace("{SCHEDULERGUID}", schedulerGuid);

    return new Promise((resolve, reject) => {
      this.http.delete(url, this.jsonOptions).toPromise().then(res => {
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    });
  }


  postFeed(feed): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = {
      headers: headers
    };

    let postFeed = this.func.recursivelyRemoveUnderscoreJSONKeys(feed);
    //Response returns obj = {feedguid:string}
    return new Promise((resolve, reject) => {
      this.http.post(REST.ADD_FEED, postFeed, options).toPromise().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err);
      });
    })
  }

  getMappings(): Promise<Array<Mapping>> {
    return new Promise((resolve, reject) => {
      this.http.get(REST.GET_MAPPINGS).toPromise().then((res: any) => {
        let mappings: Array<Mapping> = new Array<Mapping>();
        res.forEach(mapping => {
          mappings.push(new Mapping(mapping.feedmappingid, mapping.typeof));
        })
        resolve(mappings);
      }).catch(err => {
        reject(err);
      })
    })
  }

  saveNewApp(jsonApplication: App): Promise<any> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    let options = {
      headers: headers
    };
    let body = this.func.recursivelyRemoveUnderscoreJSONKeys(jsonApplication);

    delete body.editions;

    console.log(body);

    return new Promise((resolve, reject) => {
      this.http.post(REST.SAVE_APP.toString().replace('{applicationGuid}', jsonApplication.app.guid), body, options)
        .toPromise().then(res => {
          resolve(res);
        }).catch(err => {
          reject(err);
        });
    });
  }

  updateFeed(feed: Feed): Promise<any> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    feed = this.func.recursivelyRemoveUnderscoreJSONKeys(feed);

    console.log(feed);
    //console.log(JSON.stringify(feed));

    return new Promise((resolve, reject) => {
      this.http.put(REST.PUT_FEED.replace('{feedGuid}', feed.feedguid), feed, options).toPromise().then(res => {
        console.log("RESPONSE:", res);
        resolve(res);
      }).catch(err => {
        reject(err);
      })
    })
  }

  validateFeed(url): Promise<any> {
    let fullUrl = "https://live.portal.pagesuite.com/api/create_feed_helper.aspx?validate=yes&url=" + encodeURI(url);


    return new Promise((resolve, reject) => {
      this.http.get(fullUrl).subscribe((response) => {
        let editedResponse = response;
        if (editedResponse['Path'] === '*') {
          editedResponse['Path'] = '$[*]';
        }
        let rootPath = editedResponse["Path"] + ".";

        for (let thisDataNode of editedResponse["Data"]) {

          thisDataNode["RelativePath"] = thisDataNode["FullPath"].replace(rootPath, "");
          if(thisDataNode['RelativePath'].indexOf('*.') === 0) {
            thisDataNode['RelativePath'] = thisDataNode['RelativePath'].slice(2);
            thisDataNode['FullPath'] = thisDataNode['FullPath'].slice(2);
          }
        }
        resolve(editedResponse);
      }, (error) => {
        reject(error);
      });
    });
  }

  getFeedContent(url: string): Promise<any> {
    let fullUrl = "https://live.portal.pagesuite.com/api/proxy_martin.aspx?url=" + encodeURIComponent(url);

    return new Promise((resolve, reject) => {
      this.http.get(fullUrl).toPromise().then(res => {
        resolve(res);
      });
    });
  }

  parseFeed(feedguid: string): Promise<any> {
    return this.http.get(REST.PARSE_FEED.toString().replace("{feedguid}", feedguid)).toPromise();
  }

  getCacheKey(shortcode) {
    return this.http.get(REST.GET_APP_CACHE.toString().replace("{shortCode}", shortcode)).toPromise();
  }

  getArchive(pubGuid) {
    return this.http.get(REST.GET_ARCHIVE.toString()
      .replace("{pubGuid}", pubGuid)).toPromise();
  }


  uploadFileBuffer(event, url, tplName: string, articleCount: number, promoCount: number, accountGuid: string, platform: number) {
    console.log(event)

    let fileList: FileList;
    //Handles various file-types
    if (event instanceof Event) {
      //Changes scope of event for typescript compiler
      event = event;
      fileList = event.target.files;
    }
    else {
      fileList = event;
    }

    let objFormData = new FormData();

    objFormData.append('accountguid', accountGuid);
    objFormData.append('name', tplName);
    objFormData.append('articles', articleCount.toString());
    objFormData.append('promotions', promoCount.toString());
    objFormData.append('platform', platform.toString());

    for (let i = 0; i < fileList.length; i++) {
      objFormData.append('file', fileList[i]);
    }

    let objRequest = new XMLHttpRequest();

    objRequest.open("POST", url);
    objRequest.onload = () => { console.log(objRequest.response) }
    objRequest.send(objFormData);
  }

  uploadFile(event, url: string): Promise<string> {
    let fileList: FileList;
    //Handles various file-types
    if (event instanceof Event) {
      //Changes scope of event for typescript compiler
      event = event;
      fileList = event.target.files;
    }
    else {
      fileList = event;
    }

    if (fileList.length > 0) {

      for (let i = 0; i < fileList.length; i++) {
        let file: File = fileList[i];

        let headers = new HttpHeaders({ 'Content-Type': 'image/jpeg' });
        var options = {
          headers: headers
        };

        return new Promise((resolve, reject) => {
          this.http.post(url, file, options)
            .catch(error => Observable.throw(error))
            .subscribe((data: string) => {
              resolve(data)
            },
              error => reject(error)
            );
        })
      }
    }
  }

  getParsedFeedContent(feedguid) {
    return this.http.get(REST.GET_FEED_CONTENT.replace('{feedguid}', feedguid)).toPromise();
  }


  /* Mapping Objects from json into a domain modal */

  /*

    results = res.json().results.map(item => {
      return new nameOfObject(
          item.example,
          item.example2
      );
    });

  */

  //Object Mappers


  public mapFeedTemplateObj(jsonFeedTemplate): FeedTemplate {
    //TODO: 17 / 09 / 2018 Update the backend to match lowercase attributes and remove this function
   //jsonFeedTemplate = this.func.recursivelyLowercaseJSONKeys(jsonFeedTemplate);
    return new FeedTemplate(
      jsonFeedTemplate.id,
      jsonFeedTemplate.name,
      jsonFeedTemplate.rows,
      false
    );
  }

  public mapFeedObj(jsonFeed): Feed {
    //TODO: 17 / 09 / 2018 Update the backend to match lowercase attributes and remove this function
    jsonFeed = this.func.recursivelyLowercaseJSONKeys(jsonFeed);
    return new Feed(
      jsonFeed.data.name,
      jsonFeed.data.path,
      jsonFeed.data.url,
      jsonFeed.data.searchnode,
      jsonFeed.accountguid,
      jsonFeed.feedguid,
      jsonFeed.data.lastupdated,
      jsonFeed.data.mappings,
      jsonFeed.data.isdeleted,
      jsonFeed.data.preprocess
    )
  }

  public mapPageObj(jsonPage): Page {
    return new Page(
      jsonPage.type,
      jsonPage.orderof,
      jsonPage.section,
      jsonPage.articles,
      jsonPage.pageguid,
      jsonPage.templatetype,
      jsonPage.contenturl,
      jsonPage.promotions,
      jsonPage.isfullpage,
      jsonPage.level,
      jsonPage.screenshoturl,
      false,
      false,
      jsonPage.properties
    );
  }

  public mapSectionObj(jsonSection): Section {
    let Articles = new Array<Article>();

    jsonSection.articles.forEach(article => {
      Articles.push(this.mapArticleObj(article));
    });

    return new Section(
      Articles,
      jsonSection.name,
      jsonSection.sectionguid,
      jsonSection.color,
      jsonSection.logourl,
      jsonSection.adverts
    )
  }

  public mapArticleObj(jsonArticle): Article {
    return new Article(
      jsonArticle.articleguid,
      jsonArticle.author,
      jsonArticle.description,
      jsonArticle.descriptionnoscript,
      jsonArticle.headline,
      jsonArticle.image,
      jsonArticle.images,
      jsonArticle.lastupdated,
      jsonArticle.related,
      jsonArticle.section,
      jsonArticle.sharelink,
      jsonArticle.textdescription,
      jsonArticle.uniqueid,
      jsonArticle.videos,
      jsonArticle.sectionfrontheadline,
      jsonArticle.subheadline,
      jsonArticle.sectionfrontsubheadline,
      jsonArticle.isvisible,
      jsonArticle.category,
      jsonArticle.twitterhandle,
      jsonArticle.datecreated,
    );
  }

  public mapTemplateObj(jsonTemplate): Template {

    return new Template(
      jsonTemplate.templateguid,
      jsonTemplate.data,
      jsonTemplate.accountguid
    )
  }

  public mapAppObj(jsonApp): App {
    console.log("MAP APP OBJ");
    console.log(jsonApp);
    return new App(
      jsonApp.app,
      jsonApp.archive,
      jsonApp.menu,
      jsonApp.fonts,
      jsonApp.kiosk,
      jsonApp.footer,
      jsonApp.header,
      jsonApp.replica,
      jsonApp.sections,
      jsonApp.advertising,
      jsonApp.editionrules,
      jsonApp.editions,
      jsonApp.subscriptions,
      jsonApp.feed,
      jsonApp.custom,
      jsonApp.locale,
      jsonApp.masthead,
      jsonApp.portalsettings,
      jsonApp.tabs,
      jsonApp.settings,
      jsonApp.helperscreens,
      jsonApp.categoryselector
    );
  }

  public mapFlatplan(jsonFlatplan, flatplanguid): Flatplan {
    return new Flatplan(
      2,
      jsonFlatplan.name,
      jsonFlatplan.sections,
      jsonFlatplan.flow,
      flatplanguid
    );
  }

  public mapProduct(jsonProduct, productGuid): Product {
    return new Product(
      productGuid,
      jsonProduct.producttype,
      jsonProduct.applicationguid,
      jsonProduct.appleproductidentifier,
      jsonProduct.androidproductidentifier,
      jsonProduct.name,
      jsonProduct.description
    )
  }
}

import { PageArticle } from "./PageArticle";

export enum PageType {
  TEMPLATEARTICLE = "templateArticle",
  TEMPLATESECTION = "templateSection",
  PUZZLE = "puzzle",
  INTERSTITIAL = 'interstitial',
  PDF = 'pdf'
}

export enum TplType {
  HTML = "HTML",
  NATIVE = "NATIVE"
}



export class Page {

  private _type: string;
  private _orderof: number;
  private _section: string;
  private _articles: PageArticle[];
  private _pageguid: string;
  private _templatetype: TplType;
  private _contenturl: string;
  private _promotions: string[];
  private _isfullpage: boolean;
  private _level: string;
  private _screenshoturl: string;
  private _toDelete: boolean;
  private _isEdited: boolean;
  private _properties: any;

  constructor(type: string, orderOf: number, section: string, articles: PageArticle[], pageGUID: string, templateType: TplType, contenturl: string, promotions: string[], isfullpage: boolean, level: string, screenshoturl: string, toDelete: boolean, isEdited: boolean, properties: any) {

    this._type = type;
    this._orderof = orderOf;
    this._section = section;
    this._articles = articles;
    this._pageguid = pageGUID;
    this._templatetype = templateType;
    this._contenturl = contenturl;
    this._promotions = promotions;
    this._isfullpage = isfullpage;
    this._level = level;
    this._screenshoturl = screenshoturl;
    this._toDelete = toDelete;
    this._isEdited = isEdited;
    this._properties = properties;
  }


  get isEdited(): boolean {
    return this._isEdited;
  }

  set isEdited(value: boolean) {
    this._isEdited = value;
  }

  get screenshoturl(): string {
    return this._screenshoturl;
  }

  set screenshoturl(value: string) {
    this._screenshoturl = value;
  }

  get isfullpage(): boolean {
    return this._isfullpage;
  }

  set isfullpage(value: boolean) {
    this._isfullpage = value;
  }

  /**
   * Getter type
   * @return {string}
   */
  public get type(): string {
    return this._type;
  }

  /**
   * Getter orderof
   * @return {number}
   */
  public get orderof(): number {
    return this._orderof;
  }

  /**
   * Getter section
   * @return {string}
   */
  public get section(): string {
    return this._section;
  }

  /**
   * Getter articles
   * @return {Article[]}
   */
  public get articles(): PageArticle[] {
    return this._articles;
  }

  /**
   * Getter pageguid
   * @return {string}
   */
  public get pageguid(): string {
    return this._pageguid;
  }

  /**
   * Getter templatehtml
   * @return {string}
   */
  public get contenturl(): string {
    return this._contenturl;
  }

  /**
   * Getter promotions
   * @return {string[]}
   */
  public get promotions(): string[] {
    return this._promotions;
  }

  /**
   * Setter type
   * @param {string} value
   */
  public set type(value: string) {
    this._type = value;
  }

  /**
   * Setter orderof
   * @param {number} value
   */
  public set orderof(value: number) {
    this._orderof = value;
  }

  /**
   * Setter section
   * @param {string} value
   */
  public set section(value: string) {
    this._section = value;
  }

  /**
   * Setter articles
   * @param {Article[]} value
   */
  public set articles(value: PageArticle[]) {
    this._articles = value;
  }

  /**
   * Setter pageguid
   * @param {string} value
   */
  public set pageguid(value: string) {
    this._pageguid = value;
  }

  /**
 * Getter templatetype
 * @return {TplType}
 */
  public get templatetype(): TplType {
    return this._templatetype;
  }

  /**
   * Setter templatetype
   * @param {TplType} value
   */
  public set templatetype(value: TplType) {
    this._templatetype = value;
  }

  /**
   * Setter templatehtml
   * @param {string} value
   */
  public set contenturl(value: string) {
    this._contenturl = value;
  }

  /**
   * Setter promotions
   * @param {string[]} value
   */
  public set promotions(value: string[]) {
    this._promotions = value;
  }



  /**
   * Getter advertcode
   * @return {string}
   */
  public get level(): string {
    return this._level;
  }

  /**
   * Setter advertcode
   * @param {string} value
   */
  public set level(value: string) {
    this._level = value;
  }

  get toDelete(): boolean {
    return this._toDelete;
  }

  set toDelete(value: boolean) {
    this._toDelete = value;
  }

  get properties(): any {
    return this._properties;
  }

  set properties(value: any) {
    this._properties = value;
  }



}

// export class SectionFront extends Page {

// }

  /* OLD PAGE OBJECT */

  //   constructor(private _sectionName: string,
  //     private _sectionFront: boolean,
  //     private _htmlTemplate: string,
  //     private _articles: string[]) { }

  //   get sectionName(): string {
  //     return this._sectionName;
  //   }

  //   set sectionName(value) {
  //     this._sectionName = value;
  //   }

  //   get sectionFront(): boolean {
  //     return this._sectionFront;
  //   }

  //   set sectionFront(value) {
  //     this._sectionFront = value;
  //   }

  //   get htmlTemplate(): string {
  //     return this._htmlTemplate;
  //   }

  //   set htmlTemplate(value) {
  //     this._htmlTemplate = value;
  //   }


  //   get articles(): any[] {
  //     return this._articles;
  //   }

  //   set articles(value) {
  //     this._articles = value;
  //   }





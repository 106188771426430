import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalService} from "../../../../modal";
import {EditionService} from "../../../../services/edition.service";
import {Edition} from "../../../../models/edition";
import {ApplicationsService} from "../../../../services/applications.service";
import {App} from "../../../../models/App";
import {LoadingService} from "../../../../services/loading.service";
import {ToastService} from "../../../../services/toast.service";

@Component({
  selector: 'app-pdf-generation',
  templateUrl: './pdf-generation.component.html',
  styleUrls: ['./pdf-generation.component.css']
})
export class PdfGenerationComponent implements OnInit {

  @Input('edition') edition: Edition;
  @Input('application') application: App;

  constructor(private modalService: ModalService,
              private editionService: EditionService,
              private loadingService: LoadingService,
              private toastService: ToastService) { }

  ngOnInit() {
    console.log(this.edition);
  }

  closeModal() {
    this.modalService.destroy();
  }


  async reprocessPub(pubGuid) {

    this.loadingService.setLoading();

    let replicaPostObj;
    for(let publication of this.application.portalsettings.edition2replica.replicaPublications) {
      if(publication.guid === pubGuid) {
        replicaPostObj = publication;
        break;
      }
    }
    let replicaObj = [];
    replicaObj = await this.editionService.generateReplicas([replicaPostObj], this.edition.editionGUID);
    console.log(replicaObj);
    for(let localJob of this.edition.replicajobs) {
      if(localJob.replicapublicationguid == pubGuid) {
        console.log("updating job");
        localJob.jobid = replicaObj[0]['jobID'];
        localJob.replicaeditionguid = null;
        localJob.progress = 0;
      }
    }

    await this.editionService.saveEdition(this.edition);

    console.log(this.edition);

    this.loadingService.setFinished();

    this.editionService.setupPoller.next();

  }

  launchEdition(editionGuid) {
    if(editionGuid) {
      window.open('https://edition.pagesuite.com/launch.aspx?eid=' + editionGuid, "_blank");
    } else {
      this.toastService.failed("Edition not ready");
    }
  }

  async triggerEnotify(editionGuid) {
    if(editionGuid) {
      this.loadingService.setLoading();
      let url = this.application.portalsettings['edition2replica']['enotify'].replace('{editionGuid}', editionGuid);
      // url = 'https://edition.pagesuite.com/tribune/enotify.aspx?edid=7a2e7d88-5f5b-43ed-b773-9d74b75871ca';
      const enotifyResponse = await this.editionService.get(url);
      console.log(enotifyResponse);
      this.loadingService.setFinished();
      this.toastService.success(enotifyResponse);
    } else {
      this.toastService.failed("Edition not ready");
    }
  }

}
